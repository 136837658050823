import React from "react"
import Seo from "../components/Seo"
import Button from "../components/Button"
import ImageSVG from "../components/ImageSVG"
import { useStaticQuery, graphql, Link } from "gatsby"
import Icon from "../components/Icon"
import "../css/normalize.css"
import "../sass/styles.scss"

export default function NotFoundPage() {
  const data = useStaticQuery(graphql`
    query {
      sectionHeroImages: allFile(
        filter: { sourceInstanceName: { eq: "images" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)

  function handleBackgroundImport(d, path) {
    let srcPublic = "";

    d.sectionHeroImages.edges.forEach(image => {
      if (image.node.relativePath === path) {
        srcPublic = image.node.publicURL
      }
    })

    return srcPublic;
  }


  return (
    <main className="page--404" style={{
      backgroundImage: `url(${handleBackgroundImport(data, "assets/images/404.jpg")})`
    }}>

      <Seo title="404: Not found" relPath="/404"/>

      <div className="logo-container">
        <ImageSVG
          src="logo/bixal-logo-full--white.svg"
          alt="Bixal logo"
        />
      </div>

      <h1>Oops, page not found!</h1>

      <p>Go back to our homepage and we'll continue to work out the kinks.</p>

      <Link rel="noopener noreferrer" to="/">
        <Button type={"link"}>
          Go To Homepage
          <Icon type={"rightArrow"} color="#fff"></Icon>
        </Button>
      </Link>

      <div className="logo-b-container">
        <ImageSVG
          src="icons/b-404.svg"
          alt="Bixal letter 'B' logo"
        />
      </div>
    </main>
  )
}
